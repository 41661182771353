<template>
  <Transition
    name="transition-in"
    mode="out-in"
    appear
    @enter="anim_onEnter"
    @before-enter="anim_onBeforeEnter"
    @leave="anim_onLeave"
    @before-leave="anim_onBeforeLeave"
  >
    <slot />
  </Transition>
</template>

<script setup lang="ts"></script>
